import React from "react"

import Layout from "../components/layout-index"
import Image from "../components/image"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <h1 style={{
      fontFamily: 'sandbrush',
      fontSize: '18rem',
      fontWeight: '200',
      margin: 0,
      lineHeight: '0.75',
      color: '#323232'
    }}>S</h1>
  </Layout>
)

export default IndexPage
